.team {
  position: relative;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  max-width: 300px;
  height: 430px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.team .team-img {
  position: relative;
  font-size: 0;
  color: #353535;
}

.team .team-img img {
  width: 100%;
}

.team .team-social {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, .5);
  transition: all .3s;
  font-size: 0;
  z-index: 1;
  opacity: 0;
}

.team:hover .team-social {
  opacity: 1;
}

.team .team-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  padding: 11px 0 10px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  transition: all .5s;
  margin-top: 100px;
}

.team .team-social a.social-tw {
  background: #00acee;
}

.team .team-social a.social-fb {
  background: #3b5998;
}

.team .team-social a.social-li {
  background: #0e76a8;
}

.team .team-social a.social-in {
  background: #3f729b;
}

.team .team-social a.social-yt {
  background: #c4302b;
}

.team .team-social a:last-child {
  margin-right: 0;
}

.team:hover .team-social a {
  margin-top: 0;
}

.team .team-social a:hover {
  color: #222222;
  background: #ffffff;
}

.team .team-content {
  padding: 20px;
}

.team .team-content h2 {
  margin: 0 0 5px 0;
  color: #353535;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}

.team .team-content h3 {
  margin: 0 0 15px 0;
  color: #353535;
  font-size: 15px;
  font-weight: 300;
}

.team .team-content p {
  margin: 0 0 15px 0;
  color: #353535;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.team .team-skill {
  width: 100%;
}

.team .skill-name {
  text-align: left;
}

.team .skill-name p {
  display: inline-block;
  color: #353535;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.team .skill-name p:last-child {
  float: right;
}

.team .progress {
  position: relative;
  width: 100%;
  height: 5px;
  margin-bottom: 10px;
  background: #dddddd;
}

.team .progress:last-child {
  margin: 0;
}

.team .progress-bar {
  position: absolute;
  width: 0%;
  height: 5px;
  top: 0;
  left: 0;
  background: #454545;
  transition: all .7s;
  z-index: 1;
}

.team:hover .progress-bar.w-70 {
  width: 70%;
}

.team:hover .progress-bar.w-80 {
  width: 80%;
}

.team:hover .progress-bar.w-90 {
  width: 90%;
}